<template>
  <v-card class="pay-table-wrapper">
    <v-dialog v-model="filter_modal" persistent max-width="800">
      <v-card>
        <v-card-text class="pt-2">
          <v-subheader class="pl-1">Налаштування фільтру</v-subheader>
          <v-row>
            <v-col cols="12">
              <AddressElementSelect v-model="filter_data.city"
                                    label="Населений пункт" filled
                                    select_type="city"
              />
            </v-col>
            <v-col cols="12">
              <AddressElementSelect :parent_id="filter_data.city" v-model="filter_data.streets"
                                    label="Вулиця(-ки)" multiple filled
                                    select_type="street" use_parent_id
                                    :disabled="!filter_data.city"
              />
            </v-col>
            <v-col cols="12">
              <AddressElementSelect :parent_id="filter_data.streets" v-model="filter_data.buildings"
                                    label="Будинок(-ки)" multiple filled
                                    select_type="building" use_parent_id
                                    :disabled="!filter_data.streets.length"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-switch v-model="filter_data.start_balance.on"
                        color="grey"
                        label="початкове сальдо"></v-switch>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field label="від"
                            v-model="filter_data.start_balance.from_value"
                            color="grey"
                            v-decimal
              />
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field label="до"
                            v-model="filter_data.start_balance.to_value"
                            color="grey"
                            v-decimal
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-switch v-model="filter_data.start_balance_exclude_pay.on"
                        color="grey"
                        label="поч. сальдо (за мінусом оплати)"></v-switch>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field label="від"
                            v-model="filter_data.start_balance_exclude_pay.from_value"
                            color="grey"
                            v-decimal
              />
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field label="до"
                            v-model="filter_data.start_balance_exclude_pay.to_value"
                            color="grey"
                            v-decimal
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-switch v-model="filter_data.end_balance.on"
                        color="grey"
                        label="початкове сальдо"></v-switch>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field label="від"
                            v-model="filter_data.end_balance.from_value"
                            color="grey"
                            v-decimal
              />
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field label="до"
                            v-model="filter_data.end_balance.to_value"
                            color="grey"
                            v-decimal
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="grey lighten-3" @click.stop="filter_modal = false">
            Закрити налаштування фільтру
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu" v-if="!(!isNew && !can_edit)">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" sm="6" md="4" v-if="!isNew">
            <date-component v-model="date" label="Дата" disabled/>
          </v-col>
          <v-col cols="12" :sm="isNew ? 12 : 6" :md="isNew ? 12 : 4">
            <v-select filled label="Вид повідомлення" v-model="media_send_type"
                      :items="mediaSendTypeSelect"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="media_send_type ? '' : 'req-star'"
                      :disabled="!isNew"
                      color="grey"/>
          </v-col>
          <v-col cols="12" :sm="isNew ? 12 : 6" :md="isNew ? 12 : 4" v-if="!isNew">
            <v-select filled label="Статус" v-model="media_send_status"
                      :items="mediaSendStatusSelect"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="media_send_status ? '' : 'req-star'"
                      :disabled="!isNew"
                      color="grey"/>
          </v-col>
          <v-col cols="12" sm="5" md="5">
            <pre @click.stop="(!isNew && !can_edit) ? filter_modal = false : filter_modal = true"
                 class="pa-3"
                 style="cursor: pointer; background: #f0f0f0; white-space: normal; line-height: 1.3rem; color: #484848; height: 100%;"
                 v-html="getFilterText()"
            >
            </pre>
          </v-col>
          <v-col cols="12" sm="7" md="7" class="d-flex">
            <v-textarea v-model="message" color="grey"
                        hide-details
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="message ? '' : 'req-star'"
                        label="Текст повідомлення"
                        filled
                        rows="1"
                        height="100%"
                        style="height: 100%; font-size: .98rem; min-height: 62px !important;"
                        :disabled="!isNew && !can_edit"
                        class="mr-2"
                        id="message-textarea"
                        append-icon="mdi-dots-vertical"
                        @click:append="clickAppendIcon"
            >
            </v-textarea>
            <v-menu
                v-model="showMenu"
                :position-x="x"
                :position-y="y"
                max-width="300"
                absolute
                offset-y
            >
              <v-list>
                <v-list-item
                    v-for="(item, index) in textItems"
                    :key="index"
                    @click.stop="onTemplateClick(item.text)"
                >
                  <v-list-item-icon class="mt-3">
                    <v-icon :color="item.custom ? 'primary' : 'success'">mdi-text-long</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="text-wrap">
                    <v-list-item-subtitle class="text-wrap">
                      {{ item.text }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <div>
              <v-menu offset-y class="ml-2" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="secondary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      width="40"
                      height="32"
                  >
                    <v-icon color="white" size="19">mdi-help</v-icon>
                  </v-btn>
                </template>
                <v-list dense nav>
                  <v-subheader>Параметри заміни тексту</v-subheader>
                  <v-list-item
                      v-for="(item, idx) in mediaReplaceParams"
                      :key="`par-${idx}`"
                      @click.stop="onParamClick(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ item.param }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div class="text-center mt-1 font-weight-medium" style="font-size: 1rem; color: #545454">
                {{ messageCount }} / {{ Math.ceil(messageCount / symbolsByMessage) }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="pay-table">
              <div class="pay-table-header">
                <div class="pay-table-row">
                  <div class="pay-table-col">
                    №
                  </div>
                  <div class="pay-table-col editable">
                    Особовий
                  </div>
                  <div class="pay-table-col">
                    Адреса
                  </div>
                  <div class="pay-table-col">
                    Власник
                  </div>
                  <div class="pay-table-col">
                    Телефон
                  </div>
                  <div class="pay-table-col">
                    Статус
                  </div>
                </div>
              </div>
              <div class="pay-table-body" style="min-height: 40vh">
                <div class="pay-table-row"
                     v-for="(item, idx) in filtered_items"
                     :key="idx"
                >
                  <div class="pay-table-col"
                       style="border-left: 6px solid #4caf50d1; padding-left: 10px !important;"
                       :style="getMediaSendStatus(item.media_send_status, 'style')"
                  >
                    <v-icon size="18"
                            :color="getMediaSendStatus(item.media_send_status, 'color')"
                            class="mr-2"
                    >
                      {{ getMediaSendStatus(item.media_send_status, 'icon') }}
                    </v-icon>
                    {{ item.row_num }}
                  </div>
                  <div class="pay-table-col">
                    {{ item.flat.person_id }}
                  </div>
                  <div class="pay-table-col">
                    {{ item.flat.address }}
                  </div>
                  <div class="pay-table-col">
                    {{ item.flat.flat_owner.short_name }}
                  </div>
                  <div class="pay-table-col">
                    {{ item.recipient }}
                  </div>
                  <div class="pay-table-col">
                    {{ getMediaSendStatus(item.media_send_status) }}
                  </div>
                </div>
              </div>
              <div class="pay-table-pagination d-flex justify-start" v-if="rows.length">
                <div class="pa-3 d-flex align-center justify-start">
                  <v-btn text depressed icon class="mr-1"
                         @click.stop="pageStartOrEnd(true)"
                         :disabled="items_per_page > rows.length || page === 1"
                  >
                    <v-icon>mdi-chevron-double-left</v-icon>
                  </v-btn>
                  <v-btn text depressed icon
                         @click.stop="changePage(true)"
                         :disabled="page === 1"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <div class="mx-2">
                    <v-chip>
                      {{ `Сторінка: ${page} із ${pages}` }}
                    </v-chip>
                  </div>
                  <v-menu top :close-on-click="true" :close-on-content-click="true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" class="mr-2">
                        {{ `${items_per_page} на стор.` }}
                      </v-chip>
                    </template>
                    <v-list>
                      <v-list-item
                          v-for="(item, index) in items_per_page_list"
                          :key="index"
                          @click="setItemsPerPage(item)"
                      >
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn text depressed icon class="mr-1"
                         @click.stop="changePage(false)"
                         :disabled="items_per_page > rows.length || page === pages"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-btn text depressed icon
                         @click.stop="pageStartOrEnd(false)"
                         :disabled="page === pages"
                  >
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed color="secondary darken-1" text
             @click="startMediaSend"
             :disabled="startSendingDisabled"
             :loading="sending"
             class="button-accept">
        <v-icon left>mdi-send-outline</v-icon>
        Розпочати розсилку
      </v-btn>
      <v-btn depressed text color="secondary darken-1"
             @click="crud_item"
             class="button-accept"
             :disabled="!isNew && !can_edit || sending"
      >
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {MEDIA_SEND_HEADER_CREATE, MEDIA_SEND_HEADER_UPDATE, MEDIA_SEND_HEADER_REMOVE} from "@/store/actions/media_send";
import {mapActions, mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {
  mediaSendTypeSelect,
  mediaSendStatusSelect,
  getMediaSendStatus,
  mediaReplaceParams,
  format_date_from_python
} from "@/utils/icons"
import {FETCH_CITIES, FETCH_STREETS} from "@/store/actions/city";
import {FETCH_BUILDINGS} from "@/store/actions/building";
import sendMedia from "@/utils/axios/media_send"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'media_send_modal_delete'

export default {
  name: "HWP_Modal_MediaSend",
  mixins: [ModalComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  data() {
    return {
      mediaReplaceParams,
      showMenu: false,
      x: 0,
      y: 0,
      textItems: [
          '1',
          '2',
          '3'
      ],
      sending: false,
      filter_modal: false,
      message: this.item.message || null,
      mediaSendTypeSelect,
      mediaSendStatusSelect,
      date: this.item.date,
      count: this.item.count,
      success_count: this.item.success_count,
      error_count: this.item.error_count,
      filter_data: this.item.filter_data || {
        city: null,
        streets: [],
        buildings: [],
        start_balance: {on: false, from_value: null, to_value: null},
        start_balance_exclude_pay: {on: false, from_value: null, to_value: null},
        end_balance: {on: false, from_value: null, to_value: null}
      },
      media_send_type: this.item.media_send_type || null,
      media_send_status: this.item.media_send_status || null,
      rows: [],
      can_edit: this.item.media_send_status === 'wait',
      pages: 0,
      items_per_page: 13,
      page: 0,
      items_per_page_list: [10, 12, 14, 16, 18, 20, 25, 50],
    }
  },
  methods: {
    getRows(header_id) {
      sendMedia.get_rows({document_id: header_id})
          .then(response => response.data)
          .then(data => {
            this.rows = data.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.setPageSplitter()
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
    },
    clickAppendIcon(e) {
      e.preventDefault()

      sendMedia.get_sms_templates()
        .then(response => response.data)
        .then(data => {
          this.textItems = data
          this.showMenu = false
          this.x = e.clientX - 300
          this.y = e.clientY
          this.$nextTick(() => {
            this.showMenu = true
          })
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
    },
    onTemplateClick(payload) {
      if (!this.isNew && !this.can_edit) return
      this.message = payload
      this.$nextTick(() => {
        this.showMenu = false
      })
    },
    onParamClick(payload) {
      const textarea = document.getElementById('message-textarea')
      if (textarea.disabled) return
      const textBefore = textarea.value.substring(0, textarea.selectionStart)
      const textAfter = textarea.value.substring(textarea.selectionStart)
      const letterBefore = textarea.value.substr(textarea.selectionStart - 1, 1)
      const letterAfter = textarea.value.substr(textarea.selectionStart + 1, 1)
      this.message = `${textBefore}${letterBefore === ' ' ? '' : ' '}${payload.param}${letterAfter === ' ' ? '' : ' '}${textAfter}`
    },
    startMediaSend() {
      this.sending = true
      sendMedia.header_start_sending(this.itemId)
        .then(response => response.data)
        .then(data => {
          this.media_send_status = data.media_send_status
          this.rows = data.rows

          this.sending = false
          this.$store.commit(ALERT_SHOW, { text: 'Розсилку розпочато успішно', color: 'success' })
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
        .finally(() => this.sending = false)
    },
    getMediaSendStatus,
    ...mapActions(
        {
          fetchCities: FETCH_CITIES,
          fetchStreets: FETCH_STREETS,
          fetchBuildings: FETCH_BUILDINGS,
        }
    ),
    changePage(left) {
      if (left) {
        this.page = this.page === 1 ? 1 : this.page - 1
      } else {
        this.page = this.page === this.pages ? this.pages : this.page + 1
      }
    },
    setPageSplitter() {
      this.pages = Math.ceil(this.rows.length / this.items_per_page)
      this.page = this.pages ? 1 : 0

      if (this.pages * this.items_per_page - this.items_per_page > this.rows.length) {
        this.pages -= 1
      }
    },
    setItemsPerPage(per_page) {
      this.items_per_page = per_page
      this.setPageSplitter()
    },
    pageStartOrEnd(start) {
      if (start) {
        this.page = 1
      } else {
        this.page = this.pages
      }
    },
    cityChange(payload) {
      this.filter_data.city = payload.value
    },
    streetChange(payload) {
      if (payload) {
        this.filter_data.streets = payload.map(item => item.value)
      } else {
        this.filter_data.streets = []
      }
    },
    buildingChange(payload) {
      if (payload) {
        this.filter_data.buildings = payload.map(item => item.value)
      } else {
        this.filter_data.buildings = []
      }
    },
    getFilterText() {
      let text = ''

      if (this.filter_data.city) {
        text += '<strong>Населений пункт:</strong> '
        text += (this.cities.find(i => i.id === this.filter_data.city) || {}).name + '.  '
      }

      if (this.filter_data.streets.length) {
        text += '<strong>Вулиця:</strong> '
        this.filter_data.streets.forEach(i => {
          text += (this.streets.find(s => s.id === i) || {}).name + '; '
        })
        text += '  '
      }

      if (this.filter_data.buildings.length) {
        text += '<strong>Будинок:</strong> '
        this.filter_data.buildings.forEach(i => {
          text += (this.buildings.find(s => s.id === i) || {}).address + '; '
        })
        text += '  '
      }

      if (this.filter_data.start_balance.on) {
        text += '<strong>Сальдо на початок:</strong> '
        text += this.filter_data.start_balance.from_value + ' - '
        text += this.filter_data.start_balance.to_value + '.  '
      }

      if (this.filter_data.start_balance_exclude_pay.on) {
        text += '<strong>Сальдо на поч. (за мінусом оплати):</strong> '
        text += this.filter_data.start_balance_exclude_pay.from_value + ' - '
        text += this.filter_data.start_balance_exclude_pay.to_value + '.  '
      }

      if (this.filter_data.end_balance.on) {
        text += '<strong>Сальдо на кінець:</strong> '
        text += this.filter_data.end_balance.from_value + ' - '
        text += this.filter_data.end_balance.to_value + '.  '
      }

      if (!text) text = '<strong>Фільтр не встановлено. Для встановлення натисніть тут...</strong>'

      return text

    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.date = this.item.date
      this.filter_data = this.item.filter_data || {
        city: null,
        streets: [],
        buildings: [],
        start_balance: {on: false, from_value: null, to_value: null},
        start_balance_exclude_pay: {on: false, from_value: null, to_value: null},
        end_balance: {on: false, from_value: null, to_value: null}
      }
      this.count = this.item.count
      this.success_count = this.item.success_count
      this.error_count = this.item.error_count
      this.media_send_type = this.item.media_send_type || null
      this.media_send_status = this.item.media_send_status || null
      this.rows = []
      this.message = this.item.message || null
      this.can_edit = this.item.media_send_status === 'wait'
      this.setPageSplitter()

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      // this.$emit('closeModal')
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення розссилки від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Назва та МФО - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        filter_data: this.filter_data,
        media_send_type: this.media_send_type,
        message: this.message
      }

      if (this.isNew) {
        this.$store.dispatch(MEDIA_SEND_HEADER_CREATE, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(MEDIA_SEND_HEADER_UPDATE, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              if (!(this.cities || []).length) {
                this.fetchCities()
              }
              if (!(this.streets || []).length) {
                this.fetchStreets()
              }
              if (!(this.buildings || []).length) {
                this.fetchBuildings()
              }
              this.itemId = payload.id
              this.date = payload.date
              this.filter_data = payload.filter_data || {
                city: null,
                streets: [],
                buildings: [],
                start_balance: {on: false, from_value: null, to_value: null},
                start_balance_exclude_pay: {on: false, from_value: null, to_value: null},
                end_balance: {on: false, from_value: null, to_value: null}
              }
              this.count = payload.count
              this.success_count = payload.success_count
              this.error_count = payload.error_count
              this.media_send_type = payload.media_send_type || null
              this.media_send_status = payload.media_send_status || null
              this.message = payload.message || null
              this.can_edit = payload.media_send_status === 'wait'

              if (this.itemId) {
                this.getRows(this.itemId)
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(MEDIA_SEND_HEADER_REMOVE, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      cities: 'getCities',
      streets: 'getStreets',
      buildings: 'getBuildings',
    }),
    symbolsByMessage() {
      let text = this.message || ''
      this.mediaReplaceParams.forEach(i => {
        text.replace(i.param, i.value)
      })

      if (!text) return 160
      const test_string = text.match(/[аА-яЯіІїЇҐґ]/gi) || []
      if (test_string.length) {
        return 70
      } else {
        return 160
      }

    },
    messageCount() {
      let text = this.message || ''
      this.mediaReplaceParams.forEach(i => {
        text.replace(i.param, i.value)
      })
      return text.length
    },
    startSendingDisabled() {
      if (this.isNew) return true
      if (!this.rows.length) return true
      if (!this.can_edit) return true
      return this.changed;

    },
    changed() {
      let changed = 0

      if (this.isNew) return true

      if (this.item.filter_data.city !== this.filter_data.city) changed += 1
      this.item.filter_data.streets.forEach(i => {
        if (!this.filter_data.streets.includes(i)) changed += 1
      })
      this.filter_data.streets.forEach(i => {
        if (!this.item.filter_data.streets.includes(i)) changed += 1
      })

      this.item.filter_data.buildings.forEach(i => {
        if (!this.filter_data.buildings.includes(i)) changed += 1
      })
      this.filter_data.buildings.forEach(i => {
        if (!this.item.filter_data.buildings.includes(i)) changed += 1
      })

      if (this.item.filter_data.start_balance.on !== this.filter_data.start_balance.on) changed += 1
      if (this.item.filter_data.start_balance.from_value !== this.filter_data.start_balance.from_value) changed += 1
      if (this.item.filter_data.start_balance.to_value !== this.filter_data.start_balance.to_value) changed += 1

      if (this.item.filter_data.start_balance_exclude_pay.on !== this.filter_data.start_balance_exclude_pay.on) changed += 1
      if (this.item.filter_data.start_balance_exclude_pay.from_value !== this.filter_data.start_balance_exclude_pay.from_value) changed += 1
      if (this.item.filter_data.start_balance_exclude_pay.to_value !== this.filter_data.start_balance_exclude_pay.to_value) changed += 1

      if (this.item.filter_data.end_balance.on !== this.filter_data.end_balance.on) changed += 1
      if (this.item.filter_data.end_balance.from_value !== this.filter_data.end_balance.from_value) changed += 1
      if (this.item.filter_data.end_balance.to_value !== this.filter_data.end_balance.to_value) changed += 1

      return changed > 0
    },
    filtered_items() {
      return this.rows.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
    },
  }
}
</script>

<style scoped lang="scss">
.pay-table-col {
  padding: 2px !important;

  &:nth-child(1) {
    flex: 0 1 100px;
    padding-left: 16px !important;
    min-width: 100px;
  }

  &:nth-child(2) {
    flex: 0 1 140px;
    min-width: 140px;
  }

  &:nth-child(3) {
    flex: 0 1 280px;
    min-width: 280px;
  }

  &:nth-child(4) {
    flex: 0 1 170px;
    min-width: 170px;
  }

  &:nth-child(5) {
    flex: 0 1 170px;
    min-width: 170px;
  }

  &:nth-child(6) {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    min-width: 180px;
  }
}
</style>